export { wrapRootElement } from './src';

export const onRouteUpdate = ({ location }) => {
    if (typeof window !== 'undefined' && window.dataLayer) {

      const getLastPageViewParams = () => {
        let lastParams = {};

        for (let i = window.dataLayer.length - 1; i >= 0; i--) {
          if (window.dataLayer[i].event === 'virtualPageview') {
            lastParams = { ...window.dataLayer[i] };
            break;
          }
        }
        return lastParams;
      };
  
      const rawParams = getLastPageViewParams();
      const pageViewParams = {};

      if (rawParams.external_id) pageViewParams.external_id = rawParams.external_id;
      if (rawParams.email) pageViewParams.em = rawParams.email;
      if (rawParams.country) pageViewParams.country = rawParams.country;
      if (rawParams.first_name) pageViewParams.fn = rawParams.first_name;
      if (rawParams.last_name) pageViewParams.ln = rawParams.last_name;
      if (rawParams.phone) pageViewParams.ph = rawParams.phone;
      if (rawParams.gender) pageViewParams.ge = rawParams.gender;
      if (rawParams.city) pageViewParams.ct = rawParams.city;
      if (rawParams.state) pageViewParams.st = rawParams.state;
      if (rawParams.zip) pageViewParams.zp = rawParams.zip;
  
      pageViewParams.eventID = rawParams.eventID || null
      pageViewParams.page = window.location.pathname + window.location.search;


      if (typeof window.fbq === 'function') {

        window.fbq('track', 'PageView', pageViewParams, { eventID: pageViewParams.eventID });
      }
    }
  };

  export const onClientEntry = () => {


    if (window.hasAddedVirtualPageViewListener) {
        return; 
      }
    
      window.hasAddedVirtualPageViewListener = true;

    window.addEventListener('virtualPageView', () => {

      const getLastVirtualPageViewParams = () => {
        if (!window.dataLayer || !Array.isArray(window.dataLayer)) return {};
  
        let lastParams = {};

  
        for (let i = window.dataLayer.length - 1; i >= 0; i--) {
          if (window.dataLayer[i].event === 'virtualPageview') {
            lastParams = { ...window.dataLayer[i] };
            break;
          }
        }
  
        return lastParams;
      };
  
      
      const rawParams = getLastVirtualPageViewParams();

      const pageViewParams = {};

      if (rawParams.external_id) pageViewParams.external_id = rawParams.external_id;
      if (rawParams.email) pageViewParams.em = rawParams.email;
      if (rawParams.country) pageViewParams.country = rawParams.country;
      if (rawParams.first_name) pageViewParams.fn = rawParams.first_name;
      if (rawParams.last_name) pageViewParams.ln = rawParams.last_name;
      if (rawParams.phone) pageViewParams.ph = rawParams.phone;
      if (rawParams.gender) pageViewParams.ge = rawParams.gender;
      if (rawParams.city) pageViewParams.ct = rawParams.city;
      if (rawParams.state) pageViewParams.st = rawParams.state;
      if (rawParams.zip) pageViewParams.zp = rawParams.zip;
  
      pageViewParams.eventID = rawParams.eventID || null
      pageViewParams.page = window.location.pathname + window.location.search;


  
  
      if (typeof window.fbq === 'function') {
        window.fbq('track', 'PageView', pageViewParams, { eventID: pageViewParams.eventID });
      }
  
    });

    
  };