import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { config, ENVs } from 'config';

export const BlockLocationWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { geolocation } = useSelector((state: AppState) => state.user);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (window.location.pathname === '/not-allowed') return;

    if (config.ENV === ENVs.development) {
      console.log('Geolocation blocking disabled in development mode.');
      return;
    }
    if (
      geolocation?.iso_country &&
      config.BLOCKED_COUNTRIES.includes(geolocation.iso_country)
    ) {
      console.log(
        `Blocked country detected: ${geolocation.iso_country}. Redirecting to /not-allowed`,
      );
      navigate('/not-allowed');
    }
  }, [geolocation]);

  return <>{children}</>;
};
